import axios from 'axios';
import { noty } from '../services/notyService';

const client = axios.create({});
// const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
/**
 * Request Wrapper with default success/error actions
 */
const request = async (options) => {
  const pyuseridentifier =
    localStorage.getItem('pyuseridentifier') || localStorage.getItem('pyUser');
  const isSSO = window.isSSO;
  const password = localStorage.getItem('password');

  if (options.isAuthenticationNotRequired || isSSO === 'false' || !isSSO) {
    options = {
      headers: {
        'Content-Type': 'text/xml',
        // pwd: password,
        Authorization: `${options.pyuseridentifier || pyuseridentifier}:${password}`,
      },
      ...options,
    };
  } else {
    let accesstoken = localStorage.getItem('accessToken');

    if (!accesstoken) {
      return;
    }

    options = {
      headers: {
        Authorization: `Bearer ${accesstoken}`,
        // 'Time-Zone': TIMEZONE,
      },
      ...options,
    };

    if (options.contentType) {
      options.headers['Content-Type'] = options.contentType;
      // options.body = options.data
    } else {
      options.headers['Content-Type'] = 'Application/json';
      options.headers['Accept'] = 'Application/json';
    }
  }

  const onSuccess = function (response) {
    return new Promise(function (resolve, reject) {
      if (response.data) {
        resolve(typeof response.data === 'string' ? JSON.parse(response.data) : response.data);
      } else {
        onError(response.status);
      }
    });
  };

  const onError = function (error) {
    if (error) {
      switch (error?.response?.status) {
        case 401:
        case 'Error: Request failed with status code 401':
          noty('error', `Authentication error, Redirecting to login page`);
          setTimeout(() => {
            localStorage.clear();
            window.location.href = '/';
          }, 5000);
          break;
        case 404:
          noty('error', `Please try again`);
          break;
        case 400:
          noty('error', `Validation Error`);
          break;
        default:
          noty('error', `Server Error, Contact Administrator`);
          break;
      }
    } else {
      noty('error', error.message);
    }

    return Promise.reject(error.response || error.message);
  };
  return client(options).then(onSuccess).catch(onError);
};

export default request;
